/* Page Container Styles */
.aboutContainer {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  background: radial-gradient(circle, rgba(1,49,63,1) 0%, rgba(0,18,23,1) 100%);
  color: var(--color-white);
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.aboutContentContainer {
  width: 90%;
  margin: auto;
  padding: 2em;
}

.aboutContentContainer p {
  font-size: var(--font-size-11xl);
  text-align: center;
  margin: 200px 0px;
}

/* Header Styles */
.aboutHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutUsText > h2 {
  font-size: var(--font-size-31xl);
  max-width: 650px;
}

.aboutUsText > h6 {
  font-size: var(--font-size-11xl);
  font-weight: normal;
  margin: 50px 0px 0px 0px;
}

/* Benefits for Students and Recruiters Section */

/* Header (Student & Recruiter Selection) */
.aboutChoice {
  display: flex;
  justify-content: space-between;
}

.aboutChoiceHeader > h2 {
  font-size: var(--font-size-31xl);
  font-weight: normal;
  margin: 20px 0px;
}

.aboutChoiceHeader > hr {
  border: 3px solid white;
  border-radius: 10px;
}

/* Benefits */
.aboutBenefits {
  display: flex;
  justify-content: space-between;
  margin: 50px 0px;
}

/* Icon + Text Benefit Descriptions */
.aboutBenefit {
  max-width: 30%;
}

.aboutIcons {
  width: 50px;
  height: 50px;
}

.aboutBenefit > h3 {
  font-size: var(--font-size-11xl);
}

.aboutBenefit > p {
  margin: 0;
  text-align: start;
  font-size: var(--font-size-2xl);
}

/* Media Queries For Mobile / Tablet Devices */
@media screen and (max-width: 1100px) {
  .aboutUsText > h2 {
    font-size: var(--font-size-16xl);
    max-width: 70%;
  }

  .aboutChoiceHeader > h2 {
    font-size: var(--font-size-16xl);
  }
}

@media screen and (max-width: 850px) {
  .aboutBenefits {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  .aboutBenefit {
    max-width: 80%;
  }
  
  .aboutHeader > img {
    width: 250px;
    height: 233px;
  }
}

@media screen and (max-width: 600px) {
  .aboutContentContainer {
    width: 100%;
    padding: 0;
  }

  .aboutContentContainer > p {
    margin: 150px auto 200px auto;
    width: 90%;
  }

  .aboutHeader {
    flex-direction: column;
    text-align: center;
  }

  .aboutUsText > h2 {
    max-width: 100%;
    margin: 60px 0px;
  }

  .aboutChoice {
    flex-direction: column;
    text-align: center;
    gap: 50px;
  }

  .aboutChoiceHeader > hr {
    max-width: 300px;
  }

  .aboutHeader > img {
    width: 320px;
    height: 300px;
  }
}

@media screen and (max-width: 350px) {
  .aboutHeader > img {
    width: 250px;
    height: 233px;
  }
}

.footerStyles {
  margin-top: 100px;
}