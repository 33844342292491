.startupsP1Child {
    width: 100%;
    height: auto; /* Allow height to adapt */
    position: relative;
    background-color: var(--color-darkslategray);
    display: none;
    max-width: 100%;
  }
  
  .startRecruiting {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
    font-size: 50px; 
    font-weight: 700;
    font-family: Montserrat;
    z-index: 1;
    color: #02a9f7;
  }
  
  .recruitingSlogan {
    text-align: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 var(--padding-3xl) 0 var(--padding-xl);
  }
  
  .createYourProfile {
    color: white;
    margin: 0;
    position: relative;
    font-size: 25px;
    font-weight: 400;
    font-family: Montserrat;
    z-index: 1;
    text-align: center;
    width: 100%;
  }
  
  .navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Space between elements */
    padding: 0 var(--padding-30xl) 0 var(--padding-28xl);
    font-size: var(--font-size-16xl);
    color: var(--color-white);
  }
  
  .contactInfo,
  .mainContent {
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
    gap: var(--gap-8xl);
  }
  
  .contactInfo {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-xl);
    text-align: left;
    font-size: var(--font-size-43xl);
    color: var(--color-deepskyblue);
    font-family: var(--font-montserrat);
  }
  
  .contactInfo,
  .signUpFormWrapper,
  .startupsP1 {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  }
  
  .signUpFormWrapper {
    align-self: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 500px; /* Max width for the form */
  }
  
  .loginSection {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%; /* Ensure the section takes up the full width */
    padding: var(--padding-5xl); /* Add padding around the section */
    box-sizing: border-box;
  }
  
  .haveAnAccountContainer {
    position: relative;
    font-size: 16px;
    color: #fff;
    font-family: Montserrat;
    text-align: center;
    z-index: 1;
    margin-bottom: -20px;
  }
  
  .logIn {
    background: none;
    border: none;
    color: #02a9f7;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    text-decoration: underline;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
  
  .startupsP1 {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    background-color: var(--color-darkslategray);
    padding: var(--padding-21xl) var(--padding-41xl) 0;
    gap: var(--gap-65xl-5);
    overflow: hidden; /* Avoid overflow issues */
  }
  
  
.footerStartupsP2 {
  width: 90%;
  padding-right: 20px;
  padding-bottom: 30px;
  color: var(--color-white);
  text-align: center;
  margin-top: auto; /* Push footer to the bottom */
}

  /* Media Queries */
  @media screen and (max-width: 1275px) {
    .navigation {
      padding-left: var(--padding-4xl);
      padding-right: var(--padding-5xl);
      box-sizing: border-box;
    }
  }
  
  @media screen and (max-width: 750px) {
    .startRecruiting {
      font-size: var(--font-size-31xl);
      text-align: center;
    }
  
    .createYourProfile {
      font-size: var(--font-size-9xl);
    }
  
    .startupsP1 {
      gap: var(--gap-23xl);
      padding-left: var(--padding-11xl);
      padding-right: var(--padding-11xl);
      padding-top: var(--padding-15xl);
      padding-bottom: var(--padding-15xl);
    }
  }
  
  @media screen and (max-width: 450px) {
    .startRecruiting {
      font-size: var(--font-size-18xl);
      text-align: center;
      white-space: nowrap;
    }
  
    .createYourProfile {
      font-size: 18px;
    }
  
    .startupsP1 {
      gap: var(--gap-2xl);
      padding-left: var(--padding-8xl);
      padding-right: var(--padding-8xl);
      padding-top: var(--padding-10xl);
      padding-bottom: var(--padding-10xl);
    }
  
    .haveAnAccountContainer {
      font-size: 16px;
      margin-bottom: -20px;
    }
  
    .loginSection {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  