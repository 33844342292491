.homeChild {
  width: 1440px;
  height: 900px;
  position: relative;
  background-color: var(--color-darkslategray);
  display: none;
  max-width: 100%;
}

/* wereRedefining Styling */
.wereRedefiningTheContainer {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  display: inline-block;
  font-weight: 600;
  font-family: inherit;
  max-width: 100%;
  z-index: 1;
}

.network,
.recruit {
  color: #02a9f7;
}

.wereRedefiningTheWayStudeWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 14px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 46px;
  color: #fff;
}

/* Image Container Styling */
.types {
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End of Image Container Styling */
.description {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xl) 0 var(--padding-11xl);
  font-size: 40px;
  color: var(--color-white);
}

.slogan,
.sloganWrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.slogan {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-8xl);
}

.sloganWrapper {
  flex-direction: row;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var(--color-deepskyblue);
  font-family: var(--font-montserrat);
}

.types {
  height: 200px;
  width: 200px;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--color-gainsboro);
}

.frames,
.shapes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.frames {
  width: 708px;
  justify-content: space-between;
  gap: var(--gap-xl);
  z-index: 1;
}

.shapes {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-12xs) 13px 0;
  box-sizing: border-box;
}

.imAStudent {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-montserrat);
  color: var(--color-black);
  text-align: left;
  text-shadow: 0 0 25px rgba(168, 255, 53, 0.25);
}

.cta, .cta1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-7xl) var(--padding-mid)
    var(--padding-8xl);
  background-color: var(--color-deepskyblue);
  width: 180px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  box-sizing: border-box;
  white-space: nowrap; /* Ensure the text doesn't wrap */
  text-align: center; /* Ensure the text is centered within the button */
}

.cta1:hover,
.cta:hover {
  background-color: var(--color-cornflowerblue);
}

.imAStartup {
  width: 122px;
  position: relative;
  font-size: var(--font-size-lg);
  display: inline-block;
  font-family: var(--font-montserrat);
  color: var(--color-black);
  text-align: left;
  text-shadow: 0 0 25px rgba(168, 255, 53, 0.25);
}

.actions,
.buttons,
.cta1,
.home {
  display: flex;
  align-items: flex-start;
}

.buttons {
  width: 680px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  z-index: 1;
}

.actions,
.home {
  box-sizing: border-box;
}

.actions {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-12xs) 0 0;
  max-width: 100%;
}

.home {
  width: 100%;
  position: relative;
  background: radial-gradient(circle, rgba(1,49,63,1) 0%, rgba(0,18,23,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure footer is at the bottom */
  min-height: 100vh;
  padding: var(--padding-21xl) var(--padding-41xl) var(--padding-2xl);
  gap: 50px;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1100px) {
  .content {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .connectingStudentsWith {
    font-size: var(--font-size-31xl);
  }
  .anAiPowered {
    font-size: var(--font-size-13xl);
  }
  .frames {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home {
    gap: 25px;
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .connectingStudentsWith {
    font-size: var(--font-size-18xl);
  }
  .anAiPowered {
    font-size: var(--font-size-5xl);
  }
  .buttons {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap; /* Ensure they stay side by side */
    gap: var(--gap-sm);
  }
  .cta, .cta1 {
    width: 150px; /* Set the width of the buttons */
    padding: 12px 20px; /* Add more padding for a larger button */
    font-size: 18px; /* Set the font size */
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    text-align: center;
    white-space: nowrap; /* Ensure the text doesn't wrap */
  }
  .types {
    width: 120px; /* Reduce the width */
    height: 120px; /* Reduce the height */
  }
  .types img {
    width: 80%;
    height: 80%; 
  }
  .frames {
    gap: 60px
  }
  .wereRedefiningTheWayStudeWrapper {
    font-size: 25px;
  }
}

@media screen and (max-width: 1300px) {
  .Benefits_values__xOoL5 {
    font-size: --font-size-base;
  }
  
}