.chatbotWrapper {
    width: 90%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-montserrat);
    margin: 50px auto;
  }
  
  /* Default View for chatbot */
  .defaultChatbotViewContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
    margin: auto;
  }
  
  .defaultChatbotViewContainer h2 {
    font-size: 3rem;
  }
  
  .defaultChatbotViewContainer h4 {
    font-weight: 200;
    font-size: 1.8rem;
    margin: 10px 0px 30px 0px;
  }
  
  .defaultChatbotViewContainer h2 {
    font-size: var(--font-size-31xl);
  }
  
  /* Sidebar Styles */
  .sidebar {
    background-color: #00181f;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding: 1em;
    max-height: 100vh;
    max-width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgba(119, 119, 119, 0.3) transparent; 
    font-family: var(--font-montserrat);
  }
  
  .sidebar > * {
    width: 90%;
    margin: auto;
  }
  
  .studentSelection, .selectedStudent {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 8px;
  }
  
  .selectedStudent {
    background-color: #25252569;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 3px rgba(18, 18, 18, 0.5);
  }
  
  .studentSelection > img, .selectedStudent > img {
    width: 40px;
    height: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    object-fit: cover;
    box-sizing: border-box;
  }
  
  .studentSelection > button, .selectedStudent > button {
    background-color: transparent;
    color: white;
    border: none;
    max-width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    font-family: var(--font-montserrat);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .studentSelection > button p, .selectedStudent > button p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    max-width: 100;
  }
  
  .defaultChatbotViewContainer img {
    width: 45%;
    max-height: 400px;
    aspect-ratio: 1 / 1;
  }
  
  /* Only show profile icons on smaller screen sizes */
  @media screen and (max-width: 1000px) {
    .defaultChatbotViewContainer img {
      width: 70%;
    }
  }
  
  @media screen and (max-width: 750px) {
    .defaultChatbotViewContainer img {
      width: 70%;
    }
  
    .studentSelection > button, .selectedStudent > button {
      display: none;
      width: 0;
    }
  
    .selectedStudent, .studentSelection {
      padding: 0;
    }
    .sidebar {
      width: 65px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    .selectedStudent, .studentSelection {
      padding: 4px 2px;
    }
  
    .selectedStudent > img, .studentSelection > img {
      margin: auto;
    }
  }
  
  
  /* Default View Stlying for Chatbot Page */
  .chatbotDefaultContainer {
    width: 100%;
    max-height: 100%;
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    justify-content: flex-start;
  }
  
  .chatbotWrapper {
    width: 90%;
    margin: 50px auto;
  }
  
  /* Default View for chatbot */
  .chatbotActiveContainer {
    width: 100%;
    height: 100vh;
    display: flex;
  }
  
  /* Active view for chatbot container */
  .activeChatbotViewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
  }
  
  /* User message box */
  .userMessageBox {
    margin-top: auto;
    background-color: purple;
  }
  
  .botWrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    height: 450px;
    width: 50%;
    min-width: 600px;
    font-family: var(--font-montserrat);
  }
  
  .studentCarousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    height: 100%;
    justify-content: space-evenly;
  }
  
  .currentStudent {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: aliceblue;
    padding: 40px;
    border-radius: 10px;
    gap: 10px;
  }
  
  .headShot {
    width: 180px;
    height: 180px;
  }
  
  .chatWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .chatDisplayInnerContainer {
    height: 60vh;
    overflow: scroll;
    width: 100%;
    scrollbar-width: none;
  }
  
  .chatbotDisplayContainer {
    width: 95%;
    max-width: 95%;
    height: 100vh;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto 20px;
    overflow: scroll;
    scrollbar-width: none;
  }
  
  .chatDisplay {
    max-width: 100%;
    margin-top: auto;
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-areas: "profile message";
    gap: 20px;
  }
  
  .chatDisplay img {
    margin-top: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    grid-area: profile;
  }
  
  .chatDisplay > .userMessage,
  .botMessage {
    grid-area: message;
    margin: 20px 0px;
  }
  
  .studentInfo {
    width: 180px;
    height: 20px;
    margin: 0;
  }
  
  .typingIndicator {
    color: aliceblue;
  }
  
  .userMessage,
  .botMessage {
    padding: 20px 30px;
    width: 99%;
    overflow-wrap: break-word;
    box-sizing: border-box;
  }
  
  .userMessage {
    background-color: #00181f;
    color: white;
    box-shadow: 2px 2px 5px 1px rgba(18, 18, 18, 0.5);
    border-radius: 10px;
    align-self: flex-end;
  }
  
  .botMessage {
    background-color: #004d5a;
    box-shadow: 2px 2px 5px 1px rgba(18, 18, 18, 0.5);
    color: white;
    border-radius: 5px;
    align-self: flex-start;
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  .noStudentMessage {
    width: auto;
    height: auto;
    margin: 0;
    padding: 20px;
    font-size: 16pt;
    color: white;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }
  
  /* Styles for input container */
  .messageInputContainer {
    background-color: white;
    margin-top: 8vh;
    width: 100%;
    border-radius: 5px;
  }
  
  .chatInput {
    resize: none;
    height: 12vh;
    width: 100%;
    padding: 1em 2em;
    box-sizing: border-box;
    font-family: var(--font-montserrat);
    border: none;
    background-color: transparent;
  }
  
  .chatInput:focus,
  .chatInput:hover {
    outline: none;
  }
  
  .chatDivider {
    width: 100%;
    background-color: #d1d1d1;
    height: 1px;
    margin: 5px 0px 15px 0px;
  }
  
  /* Styles for chatbot buttons */
  .chatButtonContainer {
    display: flex;
    align-items: center;
    padding: 0em 1.5em;
    padding-bottom: 10px;
  }
  
  .nextMessageButton,
  .seeStudentProfileButton {
    border: none;
    border-radius: 7px;
    box-shadow: 2px 2px 5px 1px rgba(97, 97, 97, 0.5);
    color: white;
    padding: 8px 10px;
    font-family: var(--font-montserrat);
  }
  
  .nextMessageButton {
    background-color: #01313f;
    margin-right: 15px;
  }
  
  .seeStudentProfileButton {
    background-color: var(--color-deepskyblue);
  }
  
  .sendMessageButton {
    background-color: var(--color-deepskyblue);
    margin-left: auto;
    border: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px 1px rgba(97, 97, 97, 0.5);
  }
  
  .sendMessageButton img {
    width: 20px;
    height: 20px;
  }
  
  /* Modal Styling */
  
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
  }
  
  .closeButton {
    display: flex;
    margin-left: auto;
  }
  
  
  /* Media Queries */
  @media screen and (max-width: 900px) {
    .defaultChatbotViewContainer h4 {
      font-size: var(--font-size-2xl);
    }
  
    .defaultChatbotViewContainer h2 {
      font-size: var(--font-size-16xl);
    }
    
  
    .chatDisplay {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
    .botMessage{
      padding: 10px 20px;
      font-size: var(--font-size-sm);
    }  
  }
  
  
  @media screen and (max-width: 550px) {
    .closeButton {
      margin: auto;
    }
  
    .defaultChatbotViewContainer img {
      height: auto;
    }
  
    .closeButton {
      justify-content: center;
    }  
  
    .chatButtonContainer {
      flex-direction: column;
      padding: 0;
      gap: 10px;
      justify-content: center;
      box-sizing: border-box;
      margin: 10px 0px;
    }
  
    .chatButtonContainer > * {
      width: 90%;
      margin: auto;
      box-sizing: border-box;
      border-radius: 5px;
    }
  
    .chatInput {
      height: 15vh;
    }
  }