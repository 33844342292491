.p3Wrapper {
  width: 100%;
  min-height: 100vh;
  font-family: var(--font-montserrat);
  background: radial-gradient(circle, rgba(1,49,63,1) 0%, rgba(0,18,23,1) 100%);
}

.p3PageContainer {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p3ContentContainer {
  display: flex;
  max-width: 95%;
  gap: 200px;
  margin: auto;
  justify-content: space-between;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  max-width: 500px;

}

.rightColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header */
.p3Header h1 {
  color: white;
  font-size: var(--font-size-43xl);
  font-weight: bold;
  margin-bottom: 0;
}

.p3Header span {
  color: var(--color-cornflowerblue);
  font-size: var(--font-size-43xl);
  font-weight: bold;
}

/* Exclusive styling for demo header */
.demoContainer > .demoHeader {
  text-align: center;
  width: 900px;
  color: white;
  font-size: var(--font-size-43xl);
}

/* Stying for Demo Navigation */
.demoNavigation {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.careerButton{
  font-family: var(--font-montserrat);
  background-color: #ffffff;
  height: 30px;
  width: 100px;
  border-radius: 5px;
  border: 2px solid var(--color-deepskyblue);
}
  
.careerButton:hover {
  background-color: var(--color-cornflowerblue);
}

/* Other Elements */
.p3ContentContainer h2 {
  color: white;
  font-size: var(--font-size-11xl);
  font-weight: normal;
}

.p3ContentContainer img {
  height: 400px;
  width: 400px;
}

.demoTextDescription {
  font-size: var(--font-size-11xl);
  font-weight: normal;
  margin-bottom: 100px;
  text-align: center;
  color: white;
}

/* Form Styling */
.formContainer {
  display: flex;
  flex-direction: column;
  color: white;
  width: 90%;
}

.formContainer > form {
  display: flex;
  flex-direction: column;
}

.formInput {
  display: flex;
  align-items: center;
  color: var(--color-cornflowerblue);
  margin: 10px 0px;
  border-radius: 15px;
  border: 2px solid var(--color-deepskyblue);
  background-color: #003C58;
  font-family: var(--font-montserrat);
  font-size: var(--font-size-base);
}

.formInput > label {
  font-size: var(--font-size-xl);
}

.formInput > input {
  color: var(--color-cornflowerblue);
  padding: 0.7rem 10px;
  margin: 10px 0px;
  border-radius: 15px;
  border: none;
  background-color: #003C58;
  font-family: var(--font-montserrat);
  font-size: var(--font-size-base);
}

.formInput > input::placeholder {
  color: #b2b2b2;
  background-color: #003C58;
  font-family: var(--font-montserrat);
  font-size: var(--font-size-base);
}

.formInput > input:focus {
  outline: none;
  color: var(--color-deepskyblue);
  padding: 0.7rem 10px;
  margin: 10px 0px;
  border-radius: 15px;
  border: none;
  background-color: #003C58;
  font-family: var(--font-montserrat);
  font-size: var(--font-size-base);
}

.formInput > .sendIcon {
  height: 30px;
  width: 30px;
  margin-left: auto;
  margin-right: 10px;
}

.formContainer a {
  color: var(--color-deepskyblue);
  font-weight: bold;
  text-decoration: underline;
}

.activateDemoText {
  margin-top: 30px;
  text-align: center;
  
}


/* Media Queries */
@media screen and (max-width: 1200px) {
  .p3ContentContainer img {
    width: 300px;
    height: 300px;
  }

  .p3Header h1 {
    font-size: var(--font-size-31xl);
  }
  
  .p3ContentContainer h2 {
    font-size: var(--font-size-5xl);
  }

  .leftColumn {
    max-width: 400px;
  }
  
}


@media screen and (max-width: 900px) {
  .p3ContentContainer {
    gap: 50px;
  }
}


@media screen and (max-width: 700px) {
  .p3ContentContainer {
    flex-direction: column;
    text-align: center;
  }

  .activateDemoText {
    margin-bottom: 30px;
  }
  
}