.studentsP2Child {
  width: 1440px;
  height: 900px;
  position: relative;
  background-color: var(--color-darkslategray);
  display: none;
  max-width: 100%;
}

.startNetworking {
  margin: 0;
  position: relative;
  font-size: 50px;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
  text-align: center;
}

.resumeUpload {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-3xl) 0 var(--padding-xl);
}

.createYourProfile {
  margin: 0;
  position: relative;
  font-size: 25px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
  text-align: center;
}

.networking,
.profileBenefits {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.profileBenefits {
  align-self: center;
  flex-direction: row;
  padding: 0 var(--padding-30xl) 0 var(--padding-28xl);
  font-size: var(--font-size-11xl);
  color: var(--color-white);
}

.networking {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-8xl);
  max-width: 100%;
}

.main,
.studentsP2 {
  display: flex;
  box-sizing: border-box;
}

.main {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var(--color-deepskyblue);
  font-family: var(--font-montserrat);
}

.studentsP2 {
  width: 100%;
  position: relative;
  background: radial-gradient(circle, rgba(1,49,63,1) 0%, rgba(0,18,23,1) 100%);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-41xl) var(--padding-2xl);
  gap: var(--gap-65xl-5);
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1275px) {
  .profileBenefits {
    padding-left: var(--padding-4xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1100px) {
  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .startNetworking {
    display: none;
  }
  .createYourProfile {
    display: none;
  }
  .studentsP2 {
    gap: var(--gap-23xl);
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    padding-top: var(--padding-xs); /* Reduce padding at the top */
    margin-top: 0; /* Remove margin at the top */
  }
}

@media screen and (max-width: 450px) {
  .startNetworking {
    display: none
  }
  .createYourProfile {
    display: none
  }
  .studentsP2 {
    gap: 70px;
  }
}
