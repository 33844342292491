.profileContainer {
  display: flex;
  gap: 30px;
}

.profile {
  width: 250px;
}
.profileImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  padding: 5px;
  outline: 1px solid var(--color-cornflowerblue);
}

/* Makes all sub-headers blue */
.profile > .name, .profileItem > h3 {
  color: var(--color-cornflowerblue);
}

.profileInfo {
  text-align: start;
  width: 70%;
}

/* Organize profile into a column-format */
@media screen and (max-width: 1000px) {
  .profileContainer {
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
    gap: 0;
  }
}

@media screen and (max-width: 500px) {
  .profileContainer > * {
    text-align: center;
  }

  .profileImage {
    width: 150px;
    height: 150px;
  }
}